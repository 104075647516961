import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserProfileSelectors } from '@common/authentication/state/user-profile.selectors';
import { Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root',
})
export class UserBASAccessService {
  constructor(private store: Store, private router: Router) {}

  get userHasBASAccess(): boolean {
    return this.store.selectSnapshot(UserProfileSelectors.getUserHasBASAccess);
  }

  navigateToBASPage(): void {
    this.router.navigate(['/content', 'BusAcceleratorSuite'], { replaceUrl: true });
  }

  userHasBASAccessOrRedirect(): boolean {
    const userHasBASAccess = this.userHasBASAccess;

    if (!userHasBASAccess) {
      this.navigateToBASPage();
    }

    return userHasBASAccess;
  }
}
