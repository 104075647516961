import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { WindowToken } from '@common/utils/window';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PurplePageService {
  readonly STANDARD_PURPLE_PAGE_QUERY_PARAMS = 'template=blankHubTemplate&site=hub';

  constructor(private http: HttpClient, @Inject(WindowToken) private window: Window) {}
  headers = new HttpHeaders({
    Accept: 'text/html, text/plain',
  });

  getPurplePageContent(purplePagePath: string): Observable<string> {
    const fullPath = this.getFullPath(purplePagePath);

    return this.http.get(fullPath, { headers: this.headers, responseType: 'text' });
  }

  getJspItemContent(fullPath: string): Observable<string> {
    return this.http.get(fullPath, { headers: this.headers, responseType: 'text' });
  }

  private getFullPath(purplePagePath: string): string {
    let fullPath: string;

    const currentUrl = this.window.location.href;

    // TODO When upgrading to Angular Routing,
    // rewrite this with Location and ActivatedRoute
    const index = currentUrl.indexOf('?');
    if (index > -1) {
      const currentUrlQueryParams = currentUrl.substring(index);
      fullPath = `/Main/${purplePagePath}${currentUrlQueryParams}&${this.STANDARD_PURPLE_PAGE_QUERY_PARAMS}`;
    } else {
      fullPath = `/Main/${purplePagePath}?${this.STANDARD_PURPLE_PAGE_QUERY_PARAMS}`;
    }

    return fullPath;
  }
}
