import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { filter } from 'rxjs/operators';
import { UserProfile } from '../authentication/model/user-profile';
import { UserProfileSelectors } from '../authentication/state/user-profile.selectors';

export const userProfileResolverFn: ResolveFn<UserProfile> = (
  _route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot
) =>
  inject(Store)
    .select(UserProfileSelectors.getUserProfile)
    .pipe(filter((userProfile: UserProfile) => !!userProfile));
