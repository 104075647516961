import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UnsafePipe } from './unsafe.pipe';

@NgModule({
  declarations: [UnsafePipe],
  imports: [CommonModule],
  exports: [UnsafePipe],
})
export class UnsafeModule {}
