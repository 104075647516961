import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserBASAccessService } from '../../../../common/src/services/user-bas-access.service';
import { PurplePageService } from '../purple-page.service';

@Component({
  selector: 'u-purple-page',
  templateUrl: './purple-page.component.html',
  styleUrls: ['./purple-page.component.scss'],
})
export class PurplePageComponent implements OnInit {
  @Input() path: string;
  @Input() fullPath: string;

  @ViewChild('purpleContent', { static: true }) purpleContent: ElementRef;

  purplePageContent$: Observable<string>;

  constructor(
    private purplePageService: PurplePageService,
    private basAccessService: UserBASAccessService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.path = this.route.snapshot.url.map((segment) => segment.path).join('/');
    if (this.path) {
      this.purplePageService.getPurplePageContent(this.path).subscribe(
        (content) => this.renderPurplePageContent(content),
        (error) => {
          if (error.headers.BasRedirectPage) {
            this.basAccessService.navigateToBASPage();
          } else {
            this.router.navigate(['/404']);
          }
        }
      );
    }

    if (this.fullPath) {
      // Using type fullPath to get non-purple pages (formerly JspItem)
      this.purplePageService.getJspItemContent(this.fullPath).subscribe((content) => {
        this.renderPurplePageContent(content);
      });
    }
  }

  private renderPurplePageContent(content: string): void {
    const fragment = document.createElement('div');
    fragment.innerHTML = content;
    // replace(/(function )(.*\w\s*)(\(\w*.*\w*\))/, 'window.$2 = $1$3') makes `function foo()` to `window.foo = function ()`
    // which will put `foo()` in the global namespace otherwise it won't run in Angular like it did in AngularJS
    // the regExp will also cover functions with multiple arguments
    fragment.innerHTML = fragment.innerHTML.replace(/(function )(.*\w\s*)(\(\w*.*\w*\))/gm, 'window.$2 = $1$3');
    this.purpleContent.nativeElement.appendChild(fragment);

    const scripts = this.purpleContent.nativeElement.getElementsByTagName('script');
    // eslint-disable-next-line no-eval
    [...scripts].forEach((script) => eval(script.text));
  }
}
